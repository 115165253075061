<!-- eslint-disable max-len -->
<template>
  <div class="modal-backdrop" v-if="showModal">
    <div class="alert-dialog" tabindex="-1">
      <div class="alert-dialog-body">
        <div class="alert-dialog-icon">
          <div class="w-12 h-12 flex flex-col justify-center items-center rounded-xl bg-yellow-100 text-yellow-700">
            <ExclamationIcon size="24"/>
          </div>
        </div>
        <div class="alert-dialog-content">
          <h3 class="font-bold text-lg mb-4">
            Seu link de pagamento será salvo
          </h3>
          <p class="text-gray-500">
            Você poderá acessar o link depois, sem a necessidade de aplicar crédito novamente. Cada CPF tem direito a 3 aplicações por dia.
          </p>
        </div>
      </div>
      <div class="alert-dialog-footer flex-row">
        <a @click="closeModal" class="button w-full lg:w-auto cursor-pointer">
         Continuar editando
        </a>
        <button class="button button-primary w-full lg:w-auto" @click="goToPaymentLinkMenu">
          Salvar e sair
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationIcon } from '@vue-hero-icons/outline';
import { mapActions } from 'vuex';

export default {
  name: 'payment-link-saved-modal',
  components: {
    ExclamationIcon,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions('paymentLink', ['resetPaymentLinkCreation']),
    closeModal() {
      this.$emit('onCloseModal');
    },
    async goToPaymentLinkMenu() {
      await this.$router.replace({ name: 'payment-link-list' });
      this.resetPaymentLinkCreation();
    },
  },
};
</script>
