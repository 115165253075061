<template>
  <div v-if="paymentOptionsOrdered">
    <div class="text-gray-500 mb-6">
      Demonstração das condições de pagamento disponíveis para o cliente.
    </div>
    <p v-if="!isPromotionalRetention" class="font-semibold text-gray-900 mb-2">Valor de entrada</p>
    <div v-if="!isPromotionalRetention" class="flex flex-col md:flex-row gap-3 w-full mb-6">
      <DownPaymentOptionCard
        v-for="(downPayment, index) in paymentOptionsOrdered"
        :key="index"
        :index="index"
        :downPaymentOption="downPayment"
        :selected="downPaymentSelected === index"
        @click="selectDownPayment(index)"
      />
    </div>
    <div v-if="hasGracePeriod" class="component pb-6">
      <p class="font-semibold text-gray-900 mb-2">
        Vencimento da primeira parcela
      </p>
      <div class="flex gap-6">
        <button
          @click="selectDate(index)"
          v-for="(option, index) in currentDownPayment.options"
          :key="option.maxOfMaxTotalAmount"
          class="flex rounded-xl h-12 w-full flex-col
          px-1 gap-0.5 md:hidden justify-center items-center"
          style="max-width: 48px"
          :class="{
            'text-white bg-primary-700': selectedDate === index,
            'bg-gray-200 text-gray-900': selectedDate !== index,
          }"
        >
          <span class="font-bold leading-5 w-full">
            {{ moment(option.gracePeriodDueDate).format("D") }}
          </span>
          <span class="text-xs font-semibold leading-3 uppercase">
            {{ moment(option.gracePeriodDueDate).format("MMM") }}
          </span>
        </button>
        <div
          class="hidden md:flex items-center cursor-pointer
          gap-1 border border-gray-200 pl-1 pr-2 py-1 rounded-l-full
           rounded-r-full"
          v-for="(option, index) in normalizedOptions"
          :key="option.gracePeriodDueDate"
          :class="{
            'border-2 border-primary-400 bg-primary-50': selectedDate === index,
            'border-gray-200': selectedDate !== index,
          }"
        >
          <input
            class="input-custom hidden md:block"
            :id="`date-${index}`"
            type="radio"
            :checked="selectedDate === index"
            @click="selectDate(index)"
          />
          <label
            class="hidden md:block text-sm leading-5 uppercase cursor-pointer"
            :for="`date-${index}`"
          >
            {{
              moment(option.gracePeriodDueDate).format("D") +
              " " +
              moment(option.gracePeriodDueDate).format("MMM")
            }}
          </label>
        </div>
      </div>
    </div>
    <p class="font-semibold text-gray-900 mb-2">Quantidade de parcelas</p>
    <div
      class="w-full select dropdown mb-6"
      v-click-outside="onClickOutsideDropdown"
    >
      <button
        class="w-full h-11 rounded-md border-0 bg-gray-200
        button dropdown-toggle flex justify-between"
        @click="toggleDropdown"
      >
        <span
          >{{ formatInstallmentText(currentInstallment[installmentSelected]) }}
        </span>
        <ChevronDownIcon class="flex-shrink-0" size="20" />
      </button>
      <div class="dropdown-menu w-full" :class="openDropdown ? '' : 'hidden'">
        <div class="dropdown-menu-list">
          <div
            v-for="(installment, index) in currentInstallment"
            :key="index"
            class="dropdown-menu-item"
            :class="{ 'is-active': installmentSelected === index }"
          >
            <button
              @click="selectInstallment(index)"
              class="dropdown-menu-button"
            >
              <span>{{ formatInstallmentText(installment) }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full bg-gray-100 p-4 rounded-md flex flex-col gap-3">
      <div
        class="flex justify-between"
        v-if="currentDownPayment.downPaymentPercentage > 0"
      >
        <span>Entrada ({{ currentDownPayment.downPaymentPercentage }}%)</span>
        <span class="font-semibold">
          {{ currentDownPayment.downPaymentValue | moneyFilter }}
        </span>
      </div>
      <div class="flex justify-between" v-else>
        <span>Entrada</span>
        <span class="font-semibold"> - </span>
      </div>
      <div class="flex justify-between">
        <span>Parcelas</span>
        <span class="font-semibold">
          {{ formatInstallmentText(currentInstallment[installmentSelected]) }}
        </span>
      </div>
      <div class="flex justify-between">
        <span>Valor total</span>
        <span class="font-semibold">
          {{
            currentInstallment[installmentSelected].totalAmount | moneyFilter
          }}
        </span>
      </div>
      <div class="text-xs text-gray-500 text-right">
        <p>
          Juros de
          {{
            currentInstallment[installmentSelected].interestRatePM
              | interestRateFilter
          }}
          a.m. + encargos
        </p>
        <p>
          CET máximo de
          {{
            currentInstallment[installmentSelected].cetPA | interestRateFilter
          }}
          a.a.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ChevronDownIcon } from '@vue-hero-icons/solid';
import moment from 'moment';
import DownPaymentOptionCard from './DownPaymentOptionCard.vue';
import stringMixin from '../../../mixins/string';

export default {
  name: 'payment-link-payment-options',
  mixins: [stringMixin],
  components: {
    DownPaymentOptionCard,
    ChevronDownIcon,
  },
  props: {
    paymentOptions: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    downPaymentSelected: 0,
    installmentSelected: 0,
    selectedDate: 0,
    openDropdown: false,
    dueDate: null,
  }),
  computed: {
    ...mapGetters('paymentLink', ['isPromotionalRetention']),
    currentDownPayment() {
      return this.paymentOptionsOrdered[this.downPaymentSelected];
    },
    normalizedOptions() {
      if (this.currentDownPayment?.options?.length === 1) {
        return this.currentDownPayment?.options[0]?.installmentOptions;
      }
      if (this.currentDownPayment?.options?.length > 0) {
        return this.currentDownPayment?.options;
      }
      return this.currentDownPayment?.dueDateOptions;
    },
    currentOptions() {
      return this.currentDownPayment?.options?.length > 0
        && !this.currentDownPayment?.hasGracePeriod
        ? this.normalizedOptions
        : this.normalizedOptions[this.selectedDate];
    },
    currentInstallment() {
      return this.currentOptions.installmentOptions
        ? this.currentOptions.installmentOptions
        : this.currentOptions;
    },
    paymentOptionsOrdered() {
      return [...this.paymentOptions].sort(
        (a, b) => a.downPaymentPercentage - b.downPaymentPercentage,
      );
    },
    hasGracePeriod() {
      return (
        this.currentDownPayment?.hasGracePeriod
        || this.currentInstallment[this.installmentSelected]?.hasGracePeriod
      );
    },
    moment() {
      return moment;
    },
  },
  methods: {
    selectDownPayment(index) {
      this.downPaymentSelected = index;
    },
    selectInstallment(index) {
      this.installmentSelected = index;
      this.openDropdown = false;
    },
    selectDate(date) {
      this.selectedDate = date;
    },
    toggleDropdown() {
      this.openDropdown = !this.openDropdown;
    },
    onClickOutsideDropdown() {
      if (this.openDropdown) this.openDropdown = false;
    },
    formatInstallmentText(installment) {
      const { numberOfInstallments, paymentInstallments } = installment;
      return `${numberOfInstallments}x de ${this.moneyFilter(
        paymentInstallments,
      )}`;
    },
  },
};
</script>

<style lang="postcss" scoped>
.dropdown-toggle {
  padding-right: 10px;
}

.input-custom {
  cursor: pointer;
  margin: 0;
  padding: 0;
  @apply h-5 w-5 appearance-none bg-gray-300 border-none;

  &:checked {
    border: 6px solid;
    @apply border-primary-700 bg-transparent;
  }

  &:focus {
    box-shadow: none;
    @apply outline-none;
  }
}
</style>
