<!-- eslint-disable max-len -->
<template>
  <div class="flex flex-col min-h-full">
    <div class="flex items-center justify-between gap-4 bg-white border-b py-1 lg:py-2 px-6">
      <h3 class="text-gray-500 text-lg">Novo link de pagamento</h3>
      <Button aria-label="Close" kind="plain" class="-mr-3" @click="showModal">
        <XIcon size="24" />
        <span class="sr-only"> Fechar </span>
      </Button>
    </div>
    <div class="flex-grow bg-white lg:bg-gray-100 w-full">
      <div class="max-w-lg mx-auto bg-white p-6 pb-9 lg:border lg:rounded-xl lg:my-6">
        <header class="flex items-center mb-6 justify-between">
          <h1 class="font-bold text-xl leading-9">Resumo</h1>
          <span v-if="isPromotionalRetention" class="text-sm">Expira em 2 dias úteis</span>
        </header>
        <div class="space-y-5">
          <div class="flex gap-4">
            <div class="w-1/2">
              <div class="text-gray-500 text-sm mb-0.5">Valor</div>
              <span>{{ newPaymentLink.value | moneyFilter }}</span>
            </div>
            <div class="flex flex-col w-1/2">
              <span v-if="isPromotionalRetention" class="text-gray-500 text-sm mb-0.5">Estabelecimento recebe</span>
              <span v-else class="text-gray-500 text-sm mb-0.5">Expira em</span>
              <span v-if="isPromotionalRetention">{{ (newPaymentLink.value * (1 - paymentOptions[0].options[0].installmentOptions[0].retentionPercentage)) | moneyFilter }}</span>
              <span v-else>2 dias úteis</span>
            </div>
          </div>
          <div>
            <div class="text-gray-500 text-sm mb-0.5">Crédito</div>
            <Badge
              v-if="
                newPaymentLink.creditApplyStatus === 'PRE_APPROVED' &&
                newPaymentLink.status === 'SENT'
              "
              kind="warning"
            >
              <span>Incompleto</span>
            </Badge>
            <Badge v-else-if="newPaymentLink.creditApplyStatus === 'DENIED'" kind="danger">
              <span>Não aprovado</span>
            </Badge>
            <Badge
              v-else-if="newPaymentLink.creditApplyStatus === 'PRE_APPROVED'"
              chip
              kind="warning"
            >
              <span>Pré-aprovado</span>
            </Badge>
            <Badge v-else chip kind="info">
              <span>Pendente</span>
            </Badge>
          </div>
          <div class="flex flex-col">
            <span class="text-gray-500 text-sm mb-0.5">Nome do cliente</span>
            <span>
              {{ newPaymentLink.customerName }}
            </span>
          </div>
          <div class="flex flex-col">
            <span class="text-gray-500 text-sm mb-0.5">Descrição do pedido</span>
            <span>
              {{ newPaymentLink.description }}
            </span>
          </div>
          <div class="flex gap-4">
            <div class="flex flex-col w-1/2">
              <span class="text-gray-500 text-sm mb-0.5"> Celular </span>
              <span>
                {{ newPaymentLink.customerPhone | phoneFilter }}
              </span>
            </div>
            <div
              v-if="newPaymentLink.customerEmail && canShowBilletUploadButton"
              class="flex flex-col w-1/2"
            >
              <span class="text-gray-500 text-sm mb-0.5"> Email </span>
              <span>
                {{ newPaymentLink.customerEmail }}
              </span>
            </div>
          </div>
          <div v-if="!canShowBilletUploadButton">
            <div class="text-gray-500 text-sm mb-0.5">Email</div>
            <div>
              {{ newPaymentLink.customerEmail }}
            </div>
          </div>
        </div>
        <div
          v-if="canShowBilletUploadButton"
          class="flex flex-col mt-6 space-y-2 sendPDFButton"
          :key="fileRenderKey"
        >
          <span class="font-semibold text-sm">Boleto da garantia de aluguel</span>
          <div class="flex gap-4 flex-grow">
            <div
              style="max-width: 50%"
              :class="{ 'w-auto': rentGuaranteeBillet }"
              class="w-fit truncate"
            >
              <label
                for="sendPDFButton"
                :class="{
                  'pointer-events-none w-fit hover:bg-white cursor-auto': rentGuaranteeBillet,
                }"
                class="flex w-auto rounded-md border border-gray-300 cursor-pointer hover:bg-gray-50 p-2 pr-4 gap-2"
              >
                <DocumentTextIcon
                  v-if="rentGuaranteeBillet"
                  style="min-height: 24px; min-width: 24px"
                  class="h-6 w-6"
                />
                <span v-if="rentGuaranteeBillet && rentGuaranteeBillet.name" class="truncate">{{
                  rentGuaranteeBillet.name
                }}</span>
                <UploadIcon
                  v-if="!rentGuaranteeBillet"
                  style="min-height: 24px; min-width: 24px"
                  class="h-6 w-6"
                />
                <span v-if="!rentGuaranteeBillet" class="truncate">{{ "Enviar Boleto" }}</span>
              </label>
            </div>
            <div class="flex justify-end w-1/2 flex-grow">
              <button
                v-if="rentGuaranteeBillet"
                @click="removeBillet"
                class="text-primary-700 hover:text-primary-900"
              >
                Remover boleto
              </button>
            </div>
          </div>
          <input
            class="absolute opacity-0 invisible"
            id="sendPDFButton"
            type="file"
            accept="application/pdf"
            @change="onSendPdf"
          />
          <div v-if="errorMessage" class="flex flex-row items-center gap-1">
            <ExclamationCircleIcon class="w-5 text-red-600" />
            <p class="text-sm text-red-600">
              {{ errorMessage }}
            </p>
          </div>
          <span v-else class="text-sm text-gray-500">{{
            rentGuaranteeBillet
              ? `Tamanho ${rentGuaranteeBillet.size}`
              : "Arquivo PDF até 2MB"
          }}</span>
        </div>
      </div>
    </div>

    <nav class="py-4 px-6 border-t bg-white lg:sticky lg:bottom-0 lg:z-10">
      <div class="flex gap-4">
        <div class="flex flex-grow gap-4 justify-end">
          <Button
            v-if="newPaymentLink.checkoutId"
            link
            @click="showPaymentOptionsModal"
            kind="default"
          >
            Opções de parcelamento
          </Button>
          <Button
            v-if="['PENDING', 'INCOMPLETE'].includes(newPaymentLink.status)"
            link
            @click="handleGeneratePaymentLink"
            kind="primary"
          >
            Gerar link de pagamento
          </Button>
        </div>
      </div>
    </nav>

    <Modal v-if="newPaymentLink.checkoutId" :show="openPaymentOptionsModal" slotClasses="max-w-2xl">
      <section class="relative p-6">
        <div class="flex justify-between">
          <p class="text-xl font-semibold mb-3">Opções de parcelamento</p>
          <Button kind="plain" aria-label="Close" @click="showPaymentOptionsModal">
            <XIcon size="24" />
            <span class="sr-only"> Fechar </span>
          </Button>
        </div>
        <PaymentLinkPaymentOptions :paymentOptions="paymentOptions" />
      </section>
    </Modal>

    <PaymentLinkSavedModal :showModal="openModal" @onCloseModal="showModal" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import {
  XIcon,
  UploadIcon,
  DocumentTextIcon,
  ExclamationCircleIcon,
} from '@vue-hero-icons/outline';
import { Button, Badge } from '../../../components';
import stringMixin from '../../../mixins/string';
import Modal from '../components/Modal.vue';
import PaymentLinkPaymentOptions from '../components/PaymentLinkPaymentOptions.vue';
import PaymentLinkSavedModal from '../components/PaymentLinkSavedModal.vue';

export default {
  name: 'review-payment-link',
  metaInfo: {
    title: 'Novo link de pagamentos',
  },
  components: {
    XIcon,
    DocumentTextIcon,
    UploadIcon,
    ExclamationCircleIcon,
    Modal,
    PaymentLinkPaymentOptions,
    PaymentLinkSavedModal,
    Button,
    Badge,
  },
  mixins: [stringMixin],
  data: () => ({
    openModal: false,
    openPaymentOptionsModal: false,
    rentGuaranteeBillet: null,
    fileRenderKey: 0,
    errorMessage: null,
  }),
  computed: {
    ...mapState('retailer', ['currentRetailer']),
    ...mapState('paymentLink', ['newPaymentLink', 'paymentOptions']),
    ...mapGetters('paymentLink', ['pendency', 'isPromotionalRetention']),
    canShowBilletUploadButton() {
      return this.currentRetailer.type === 'imobiliaria';
    },
  },
  methods: {
    ...mapActions('paymentLink', ['generatePaymentLink', 'resetPaymentLinkCreation']),
    ...mapActions('loading', ['setIsLoading']),
    showModal() {
      this.openModal = !this.openModal;
    },
    async showPaymentOptionsModal() {
      this.openPaymentOptionsModal = !this.openPaymentOptionsModal;
    },
    async goToPaymentLinkMenu() {
      this.resetPaymentLinkCreation();
      await this.$router.replace({ name: 'payment-link-list' });
    },
    async handleGeneratePaymentLink() {
      if (this.canShowBilletUploadButton) {
        if (!this.rentGuaranteeBillet) {
          this.errorMessage = 'Necessário anexar arquivo';
          return;
        }

        if (this.rentGuaranteeBillet.bytes / 1024 > 2048) {
          this.errorMessage = 'Tamanho de arquivo acima do limite máximo de 2MB';
          return;
        }
      }

      this.setIsLoading({ isLoading: true, loadingMessage: 'Gerando link de pagamento' });
      if (this.rentGuaranteeBillet) {
        await this.generatePaymentLink(this.rentGuaranteeBillet.base64);
      } else {
        await this.generatePaymentLink();
      }
      await this.$router.replace({ name: 'success-payment-link' });
      this.setIsLoading({ isLoading: false, showIcon: 'success', showIconDelay: 1500 });
    },
    removeBillet() {
      this.errorMessage = null;
      this.rentGuaranteeBillet = null;
      this.fileRenderKey += 1;
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      // eslint-disable-next-line no-restricted-properties
      return `${Math.round(parseFloat((bytes / Math.pow(k, i)).toFixed(dm)))}${sizes[i]}`;
    },
    onSendPdf(e) {
      if (!e.target.files.length) return;
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // reader.onprogress = (on) => {
      //   this.rentGuaranteeBilletProgress = Math.round((on.loaded / on.total) * 100);
      // };
      reader.onload = (on) => {
        const base64 = on.target.result.replace(/data:.*base64,/, '');
        this.rentGuaranteeBillet = {
          name: file.name,
          size: this.formatBytes(file.size),
          bytes: file.size,
          base64,
        };
        this.errorMessage = null;
      };
    },
  },
};
</script>
<style lang="postcss" scoped>
.sendPDFButton {
  > input {
    z-index: -1;
  }
}
</style>
