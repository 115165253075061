<template>
  <div
    class="w-full p-3 relative border border-gray-300 rounded-md cursor-pointer"
    :class="{ 'border-primary-400 bg-primary-50': selected }"
    @click="onClick"
  >
    <div
      class="absolute top-3 right-3 w-5 h-5 rounded-full"
      :class="selected
        ? 'bg-white border-6 border-collapse border-primary-700'
        : 'bg-gray-300 border-0'"
    ></div>
    <div v-if="isDownPaymentZero"
      class="h-full flex flex-col justify-center"
    >
      <span class="text-sm text-gray-500">Sem entrada</span>
    </div>
    <div v-else class="h-full flex flex-col">
      <p class="text-sm text-gray-500">
        Entrada de {{ downPaymentOption.downPaymentPercentage }}%
      </p>
      <p class="font-semibold">
        {{ downPaymentOption.downPaymentValue | moneyFilter }}
      </p>
      <p v-if="index !== 0" class="text-xs text-primary-600">
        Economize até {{
          downPaymentOption.economyValue.toLocaleString('pt-br',
          { style: 'currency', currency: 'BRL' })
        }}
      </p>
    </div>
  </div>
</template>

<script>
import stringMixin from '../../../mixins/string';

export default {
  name: 'down-payment-option-card',
  mixins: [stringMixin],
  props: {
    downPaymentOption: {},
    index: {
      type: Number,
      default: 0,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDownPaymentZero() {
      return this.downPaymentOption?.downPaymentPercentage === 0;
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
.border-6 {
  border-width: 6px;
}
</style>
