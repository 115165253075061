<template>
  <div
    v-if="show"
    class="modal-backdrop flex flex-col justify-center transition-all duration-2000"
    :class="show ? 'translate-y-0' : 'translate-y-full'"
  >
    <div class="w-3/5 m-auto rounded-xl bg-white" :class="slotClasses">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    slotClasses: {
      type: String,
      required: false,
    },
  },
};
</script>
